<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Bitácora Novedades</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Bitácora Novedades</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header p-0">
                <div class="row">
                  <div class="col-md-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="tab-bitacoras"
                          data-toggle="tab"
                          href="#bitacoras"
                          >Bitacoras</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="tab-graficasBitacoras"
                          data-toggle="tab"
                          href="#graficasBitacoras"
                          >Graficas</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="bitacoras">
                  <!-- Filtros Bitacora -->
                  <div class="card-body pb-2">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-gradient-danger"
                            @click="limpiarFiltros()"
                          >
                            <i class="fas fa-broom"></i>
                          </button>
                          <button
                            type="button"
                            class="btn bg-gradient-navy"
                            title="Actualizar"
                            @click="getIndex()"
                          >
                            <i class="fas fa-sync-alt fa-spin"></i>
                          </button>
                          <button
                            type="button"
                            class="btn bg-gradient-primary"
                            data-toggle="modal"
                            title="Crear caso"
                            data-target="#modal-detalle-bitacora"
                            @click="abrirModal('Crear', null)"
                            v-if="
                              $store.getters.can(
                                'admin.bitacoraNovedades.create'
                              )
                            "
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                          <button
                            type="button"
                            class="btn bg-gradient-success"
                            data-toggle="modal"
                            data-target="#modal_bitacora_export"
                            v-if="
                              $store.getters.can(
                                'admin.bitacoraNovedades.export'
                              )
                            "
                            @click="
                              $refs.bitacoraNovedadadesExport.limpiarFiltros()
                            "
                          >
                            <i class="far fa-file-excel"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center mt-3">
                      <div class="form-group col-md-2">
                        <label>N° Bitacora</label>
                        <input
                          type="number"
                          v-model="filtros.id"
                          placeholder="N° Bitacora"
                          label="id"
                          class="form-control form-control-sm"
                          @keyup.enter="getIndex()"
                        />
                      </div>

                      <div class="form-group col-md-3">
                        <label>Sitio</label>
                        <v-select
                          v-model="slct_sitio"
                          class="form-control form-control-sm p-0"
                          placeholder="Nombre"
                          label="nombre"
                          :options="listasForms.sitios"
                          @input="getIndex()"
                        ></v-select>
                      </div>

                      <div class="form-group col-md-2">
                        <label>Linea Negocio</label>
                        <v-select
                          v-model="slct_lineaNegocio"
                          class="form-control form-control-sm p-0"
                          placeholder="Nombre"
                          label="nombre"
                          multiple
                          :options="listasForms.linea_negocio"
                          @input="selectLineaNegocio()"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-3">
                        <label>Tipo Novedad</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_novedad"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="tipo_novedad in listasForms.tipo_novedad"
                            :key="tipo_novedad.numeracion"
                            :value="tipo_novedad.numeracion"
                          >
                            {{ tipo_novedad.descripcion }}
                          </option>
                        </select>
                      </div>

                      <div class="form-group col-md-2">
                        <label>Estado</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-2">
                        <label>Fecha Inicial</label>
                        <input
                          type="Date"
                          min="1"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_inicial"
                          @keyup.enter="getIndex()"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label>Fecha Final</label>
                        <input
                          type="Date"
                          min="1"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_final"
                          @keyup.enter="getIndex()"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 mt-3">
                        <div
                          class="alert alert-default-warning border border-warning col-md-12 shadow"
                        >
                          <h5 class="text-center">
                            <i class="icon fas fa-exclamation-triangle"></i>
                            Atención!
                          </h5>
                          <p class="text-center mb-0">
                            Por favor verificar que la novedad que va a
                            diligenciar no se encuentre registrada previamente,
                            para evitar duplicidad. Gracias.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Div Bitacoras Creadas -->
                  <div class="card">
                    <div class="card-header pt-2 pb-2">
                      <section class="content">
                        <div class="container-fluid">
                          <div class="row">
                            <div
                              class="col-md-4"
                              v-for="bitacora in bitacoras.data"
                              :key="bitacora.id"
                            >
                              <div class="card mb-4">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-default py-2"
                                  data-toggle="modal"
                                  data-target="#modal-detalle-bitacora"
                                  @click="abrirModal('Editar', bitacora)"
                                >
                                  <h5 class="mb-0">
                                    {{ AsuntoLong(bitacora.asunto) }}
                                  </h5>
                                </button>
                                <div class="card-body">
                                  <div class="">
                                    Tipo Novedad:
                                    <span class="badge bg-gradient-info">{{
                                      bitacora.tNovedad
                                    }}</span>
                                  </div>
                                  <div
                                    class=""
                                    v-if="bitacora.linea_negocio.length > 0"
                                  >
                                    Línea Negocio:
                                    <small
                                      class="badge bg-gradient-navy"
                                      v-for="linea in bitacora.linea_negocio"
                                      :key="linea.id"
                                      >{{ linea.nombre }}</small
                                    >
                                  </div>
                                  <div class="" v-else>
                                    Línea Negocio:
                                    <span class="badge bg-gradient-danger"
                                      >Sin Asignar</span
                                    >
                                  </div>

                                  <div v-if="bitacora.sitios.length > 0">
                                    Sitios:
                                    <span
                                      class="badge bg-gradient-success"
                                      v-for="sitio in bitacora.sitios"
                                      :key="sitio.id"
                                    >
                                      {{ sitio.nombre }}
                                    </span>
                                  </div>
                                  <div v-else>
                                    Sitio:
                                    <span class="badge bg-gradient-olive">
                                      {{ bitacora.sitio_text }}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  class="card-footer d-flex justify-content-between bg-gradient-dark"
                                >
                                  <div class="row">
                                    <small
                                      >Fecha de Ingreso:
                                      {{ bitacora.fecha_hora_inicial }}</small
                                    >
                                    <small v-if="bitacora.fecha_hora_final"
                                      >Fecha de Cierre:
                                      {{ bitacora.fecha_hora_final }}</small
                                    >
                                  </div>

                                  <div class="justify-content-center">
                                    <small
                                      class="badge"
                                      :class="
                                        bitacora.estado == 2
                                          ? 'bg-gradient-warning'
                                          : bitacora.estado == 1
                                          ? 'bg-gradient-success'
                                          : ''
                                      "
                                    >
                                      Estado: {{ bitacora.nEstado }}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <BitacoraNovedadadesExport
                      ref="bitacoraNovedadadesExport"
                    />
                  </div>
                  <div class="card-footer">
                    <div class="float-left" v-if="bitacoras.total">
                      <p>
                        Mostrando del <b>{{ bitacoras.from }}</b> al
                        <b>{{ bitacoras.to }}</b> de un total:
                        <b>{{ bitacoras.total }}</b> Registros
                      </p>
                    </div>
                    <div class="float-left" v-else>
                      <p>
                        <span class="badge badge-danger">
                          No hay registros para mostrar
                        </span>
                      </p>
                    </div>
                    <pagination
                      :data="bitacoras"
                      @pagination-change-page="getIndex"
                      :limit="9"
                      class="float-right m-0"
                    ></pagination>
                  </div>
                </div>
                <!-- Gráficas Bitácoras -->
                <div class="tab-pane" id="graficasBitacoras">
                  <section class="col-12">
                    <BitacoraNovedadesGraficas
                      ref="BitacoraNovedadesGraficas"
                    />
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Modal Seguimientos -->
    <div
      class="modal fade"
      data-backdrop="static"
      data-keyboard="false"
      id="modal-detalle-bitacora"
    >
      <div class="modal-dialog modal-lg ">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              {{ modal.accion }} {{ modal.title }}
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
              id="close-modal"
              @click="getIndex()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul
              v-if="modal.accion == 'Editar'"
              class="nav nav-tabs"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-DetalleBitacora"
                  data-toggle="tab"
                  href="#DetalleBitacora"
                  >Bitácora</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-SeguimientoBitacora"
                  data-toggle="tab"
                  href="#SeguimientoBitacora"
                  >Seguimiento Bitácora</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-AdjuntosBitacora"
                  data-toggle="tab"
                  href="#AdjuntosBitacora"
                  >Adjunto</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="DetalleBitacora">
                <div class="card-body">
                  <fieldset class="well card">
                    <legend
                      class="well-legend text-bold bg-frontera text-light"
                    >
                      Formulario General
                    </legend>
                    <div class="row">
                      <!-- Fecha Bitácora -->
                      <div class="form-group col-md-4">
                        <label for="fecha_hora_inicial">Fecha y Hora</label>
                        <input
                          type="datetime-local"
                          class="form-control form-control-sm"
                          id="fecha_hora_inicial"
                          placeholder="Fecha y Hora Inicial"
                          v-model="bitacora.fecha_hora_inicial"
                          :class="
                            $v.bitacora.fecha_hora_inicial.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          :disabled="bloq"
                          v-bind:max="new Date().toISOString().slice(0, 16)"
                        />
                      </div>
                      <!-- Tipo Novedad Bitácora -->
                      <div class="form-group col-md-3">
                        <label for="Sitio">Tipo novedad</label>
                        <select
                          id="Sitio"
                          class="form-control form-control-sm"
                          v-model="bitacora.tipo_novedad"
                          :class="
                            $v.bitacora.tipo_novedad.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          :disabled="bloq"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_novedad in listasForms.tipo_novedad"
                            :key="tipo_novedad.numeracion"
                            :value="tipo_novedad.numeracion"
                          >
                            {{ tipo_novedad.descripcion }}
                          </option>
                        </select>
                      </div>
                      <!-- Estado Bitácora -->
                      <div class="form-group col-md-3">
                        <label for="nombre">Estado</label>
                        <select
                          id="estado"
                          class="form-control form-control-sm"
                          v-model="bitacora.estado"
                          :class="
                            $v.bitacora.estado.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          :disabled="estado == 2"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.id"
                            :value="estado.id"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </div>
                      <!-- Diferido Bitácora -->
                      <div class="form-group col-md-2">
                        <div
                          class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success mt-3 pt-3"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="diferido"
                            v-model="ck_diferido"
                            @change="limpiarDiferidoTabla()"
                            :disabled="estado == 2"
                          />
                          <label class="custom-control-label" for="diferido"
                            >Diferido</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <!-- Sitio Bitácora -->
                      <div class="form-group col-md-6">
                        <div class="custom-control custom-checkbox">
                          <input
                            class="custom-control-input custom-control-input-primary custom-control-input"
                            type="checkbox"
                            id="check_sitio"
                            v-model="ck_sitio"
                            @change="limpiarcksitio"
                            :disabled="modal.accion == 'Editar'"
                          />
                          <label class="custom-control-label" for="check_sitio"
                            >Sitio</label
                          >
                        </div>
                        <div class="form-group mt-1 pt-0">
                          <input
                            v-if="ck_sitio"
                            id="sitio_text"
                            type="text"
                            class="form-control form-control-sm"
                            v-model="bitacora.sitio_text"
                            placeholder="Nombre"
                            :class="
                              $v.bitacora.sitio_text.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="bloq"
                          />
                          <v-select
                            v-else
                            v-model="bitacora.sitios"
                            placeholder="Nombre"
                            class="form-control form-control-sm p-0 select2-selection select2-selection--multiple"
                            label="nombre"
                            multiple
                            :options="listasForms.sitios"
                            :class="
                              $v.bitacora.sitios.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="bloq"
                          ></v-select>
                        </div>
                      </div>
                      <!-- Línea Negocio Bitácora -->
                      <div class="form-group col-md-6">
                        <label>Linea Negocio</label>
                        <v-select
                          v-model="bitacora.linea_negocio"
                          placeholder="Nombre"
                          label="nombre"
                          multiple
                          :options="listasForms.linea_negocio"
                          :disabled="bloq"
                        ></v-select>
                      </div>
                    </div>
                    <div class="row">
                      <!-- Asunto Bitácora -->
                      <div class="form-group col-md-6">
                        <label for="nombre">Asunto</label>
                        <textarea
                          v-model="bitacora.asunto"
                          cols="30"
                          rows="2"
                          class="form-control form-control-sm"
                          :class="
                            $v.bitacora.asunto.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          :disabled="bloq"
                        ></textarea>
                      </div>
                      <!-- Descripción Bitácora -->
                      <div class="form-group col-md-6">
                        <label for="nombre">Descripción Bitácora</label>
                        <textarea
                          v-model="bitacora.descripcion"
                          cols="30"
                          rows="2"
                          class="form-control form-control-sm"
                          :class="
                            $v.bitacora.descripcion.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          :disabled="estado == 2"
                        ></textarea>
                      </div>
                    </div>
                    <!-- Fecha Cierre -->
                    <div class="row">
                      <div class="col-md-4"></div>
                      <div class="col-md-4" v-if="bitacora.estado == 2">
                        <label for="fecha_hora_final"
                          >Fecha y Hora de Cierre</label
                        >
                        <input
                          type="datetime-local"
                          class="form-control form-control-sm"
                          id="fecha_hora_final"
                          placeholder="Fecha y Hora Final"
                          v-model="bitacora.fecha_hora_final"
                          :class="
                            $v.bitacora.fecha_hora_final.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @change="validarFecha()"
                          :disabled="estado == 2"
                        />
                      </div>
                    </div>
                  </fieldset>
                  <!-- Diferidos Tabla -->
                  <fieldset class="well card" v-show="ck_diferido">
                    <legend
                      class="well-legend text-bold bg-frontera text-light"
                    >
                      Diferidos
                    </legend>
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          class="table table-bordered table-striped table-hover table-sm text-xs"
                        >
                          <thead class="bg-dark text-center">
                            <tr>
                              <th class="text-nowrap col-2">
                                Acciones
                                <span
                                  type="button"
                                  class="btn btn-sm bg-gradient-success text-white ml-1"
                                  id="agregarDiferido"
                                  @click="addDiferido()"
                                  :disabled="estado_diferido == 1"
                                  style="cursor: pointer"
                                >
                                  <i class="fas fa-plus"></i>
                                </span>
                              </th>
                              <th class="col-3 p-2">Lugar Diferido</th>
                              <th class="col-3 p-2"># Diferido</th>
                            </tr>
                          </thead>
                          <tbody id="tbody">
                            <tr
                              v-for="(difer, index) in diferidos"
                              :key="index"
                            >
                              <!-- Acciones -->
                              <td class="text-center">
                                <div
                                  class="btn-group"
                                  v-if="estado_diferido != 2"
                                >
                                  <!-- Editar Diferido -->
                                  <button
                                    type="button"
                                    class="btn alert-default-dark"
                                    :disabled="estado_diferido != -1"
                                    @click="editDiferido(index)"
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                  <!-- Eliminar Diferido -->
                                  <button
                                    class="btn alert-default-danger"
                                    :disabled="estado_diferido != -1"
                                    @click="destroyDiferido(index)"
                                  >
                                    <i class="fas fa-trash"></i>
                                  </button>
                                </div>
                                <div
                                  class="btn-group"
                                  v-if="
                                    estado_diferido == 2 &&
                                      index_diferido == index
                                  "
                                >
                                  <!-- Guardar Diferido -->
                                  <button
                                    type="button"
                                    class="btn btn-sm-2 alert-default-success"
                                    v-show="
                                      diferido.campo_diferido &&
                                        diferido.n_diferido
                                    "
                                    @click="updateDiferido(index)"
                                  >
                                    <i class="fas fa-save"></i>
                                  </button>
                                  <!-- Cancelar Diferido -->
                                  <button
                                    class="btn btn-sm-2 alert-default-danger"
                                    @click="cancelDiferido()"
                                  >
                                    <i class="fas fa-broom"></i>
                                  </button>
                                </div>
                                <div
                                  class="btn-group"
                                  v-if="
                                    estado_diferido == 2 &&
                                      index_diferido !== index
                                  "
                                >
                                  <!-- Editar Diferido -->
                                  <button
                                    type="button"
                                    class="btn btn-sm-2 alert-default-black"
                                    disabled
                                    @click="editDiferido(index)"
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                  <!-- Eliminar Diferido -->
                                  <button
                                    class="btn btn-sm-2 alert-defaultdanger"
                                    disabled
                                    @click="destroyDiferido(index)"
                                  >
                                    <i class="fas fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                              <!-- Campo Diferido -->
                              <td class="text-center">
                                <div
                                  v-if="
                                    index_diferido === index &&
                                      estado_diferido === 2
                                  "
                                >
                                  <input
                                    type="text"
                                    placeholder="Nombre Diferido"
                                    class="form-control form-control-sm"
                                    id="nombre"
                                    v-model="diferido.campo_diferido"
                                    :class="
                                      diferido.n_diferido >= 0 &&
                                      diferido.n_diferido != null &&
                                      diferido.n_diferido != ''
                                        ? 'is-valid'
                                        : 'is-invalid'
                                    "
                                  />
                                  <span
                                    v-show="
                                      diferido.campo_diferido == '' ||
                                        diferido.campo_diferido == null
                                    "
                                    class="text-danger"
                                    ><small>*Campo requerido</small></span
                                  >
                                </div>
                                <div v-else>
                                  {{ difer.campo_diferido }}
                                </div>
                              </td>
                              <!-- Número Diferido -->
                              <td class="text-center">
                                <div
                                  v-if="
                                    index_diferido === index &&
                                      estado_diferido === 2
                                  "
                                >
                                  <input
                                    type="text"
                                    placeholder="N° Diferido"
                                    class="form-control form-control-sm"
                                    id="n_diferido"
                                    v-model="diferido.n_diferido"
                                    :class="
                                      diferido.n_diferido >= 0
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                  />
                                  <span
                                    v-show="
                                      diferido.n_diferido < 0 ||
                                        diferido.n_diferido == null ||
                                        diferido.n_diferido == ''
                                    "
                                    class="text-danger"
                                    ><small>*Campo requerido</small></span
                                  >
                                </div>
                                <div v-else>
                                  {{ difer.n_diferido }}
                                </div>
                              </td>
                            </tr>
                            <!-- Agregar Diferidos -->
                            <tr v-if="estado_diferido === 1">
                              <td>
                                <div class="text-center">
                                  <button
                                    class="btn btn-sm alert-default-success"
                                    v-show="
                                      diferido.campo_diferido &&
                                        diferido.n_diferido
                                    "
                                    @click="saveDiferido()"
                                  >
                                    <i class="fas fa-save"></i>
                                  </button>
                                  <button
                                    class="btn btn-sm alert-default-danger"
                                    @click="cancelDiferido()"
                                  >
                                    <i class="fas fa-broom"></i>
                                  </button>
                                </div>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Nombre Diferido"
                                  class="form-control form-control-sm"
                                  id="nombre"
                                  v-model="diferido.campo_diferido"
                                  :class="
                                    diferido.campo_diferido
                                      ? 'is-valid'
                                      : 'is-invalid'
                                  "
                                />
                                <span
                                  v-show="
                                    diferido.campo_diferido == '' ||
                                      diferido.campo_diferido == null
                                  "
                                  class="text-danger"
                                  ><small>*Campo requerido</small></span
                                >
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder="N° Diferido"
                                  class="form-control form-control-sm"
                                  id="n_diferido"
                                  v-model="diferido.n_diferido"
                                  :class="
                                    diferido.n_diferido >= 0 &&
                                    diferido.n_diferido != null &&
                                    diferido.n_diferido != ''
                                      ? 'is-valid'
                                      : 'is-invalid'
                                  "
                                />
                                <span
                                  v-show="
                                    diferido.n_diferido < 0 ||
                                      diferido.n_diferido == null ||
                                      diferido.n_diferido == ''
                                  "
                                  class="text-danger"
                                  ><small>*Campo requerido</small></span
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </fieldset>
                  <!-- Archivo Bitácora -->
                  <div class="mt-2 pt-2">
                    <BitacoraNovedadesImagen ref="BitacoraNovedadesImagen" />
                  </div>
                </div>
                <!-- Botón Guardar -->
                <div
                  class="modal-footer justify-content-between"
                  v-if="
                    /* bitacora.estado == 1 && */
                    !$v.bitacora.$invalid && !$v.diferidos.$invalid
                  "
                >
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="
                      $store.getters.can('admin.bitacoraNovedades.save') &&
                      modal.accion == 'Crear'
                        ? save()
                        : saveSegui()
                    "
                  >
                    Guardar
                  </button>
                </div>
              </div>
              <!-- Seguimiento Bitácora -->
              <div class="tab-pane" id="SeguimientoBitacora">
                <div class="card-body p-1">
                  <table
                    class="table table-bordered table-striped table-hover table-sm"
                    style="font-size: 0.85em;"
                  >
                    <thead class="bg-dark">
                      <tr>
                        <th class="text-center">Id</th>
                        <th class="col-2 text-center">Descripción</th>
                        <th class="col-2 text-center">Usuario ID</th>
                        <th class="col-2 text-center">Campo Diferido</th>
                        <th class="col-2 text-center">N° Diferido</th>
                        <th class="col-2 text-center">Fecha de Seguimiento</th>
                        <th class="text-center">Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(historicos, index) in bitacora.historicos"
                        :key="index"
                      >
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">
                          {{ historicos.descripcion }}
                        </td>
                        <td class="text-center">
                          {{ historicos.usuario.name }}
                        </td>
                        <td class="text-center">
                          {{ historicos.campo_diferido }}
                        </td>
                        <td class="text-center">
                          {{ historicos.n_diferido }}
                        </td>
                        <td class="text-center">
                          {{ formatearFecha(historicos.created_at) }}
                        </td>
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="
                              historicos.estado == 1
                                ? 'badge-info'
                                : historicos.estado == 2
                                ? 'badge-warning'
                                : ''
                            "
                            >{{ historicos.nEstado }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- Adjuntos Imagenes -->
              <div class="tab-pane" id="AdjuntosBitacora">
                <div class="card-body">
                  <div v-if="bitacora.adjuntos && bitacora.adjuntos.length > 0">
                    <div v-for="adjunto in bitacora.adjuntos" :key="adjunto.id">
                      <ul>
                        <a
                          :href="uri_docs + adjunto.link"
                          target="_blank"
                          style="display: flex; align-items: center; justify-content: space-between;"
                        >
                          <div v-if="esImagen(adjunto.link)">
                            <img
                              :src="uri_docs + adjunto.link"
                              alt=""
                              style="width:150px; height:150px;"
                            />
                          </div>
                          <div v-else style="width:150px; height:150px;">
                            <i
                              v-if="esPDF(adjunto.link)"
                              class="fas fa-file-pdf"
                              style="font-size: 100px;"
                            ></i>
                          </div>
                          <span class="fecha-adjunto"
                            >Fecha de Seguimiento:
                            {{ formatearFecha(adjunto.created_at) }}</span
                          >
                        </a>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="small-box bg-gradient-info"
                    style="min-height: 100px"
                    v-else
                  >
                    <div class="inner">
                      <h3>No Hay Adjuntos Asociados</h3>
                    </div>
                    <div class="icon">
                      <i class="far fa-eye-slash"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import BitacoraNovedadesGraficas from "./BitacoraNovedadesGraficas";
import BitacoraNovedadesImagen from "./BitacoraNovedadesImagen";
import BitacoraNovedadadesExport from "./BitacoraNovedadadesExport";
import moment from "moment";

export default {
  name: "BitacoraNovedadades",
  components: {
    pagination,
    Loading,
    vSelect,
    BitacoraNovedadesGraficas,
    BitacoraNovedadesImagen,
    BitacoraNovedadadesExport,
  },

  data() {
    return {
      cargando: false,
      bloq: false,
      ck_sitio: false,
      ck_diferido: false,
      filtros: {},
      bitacora: {},
      bitacoras: {},
      sitios: [],
      diferido: {
        campo_diferido: null,
        n_diferido: null,
      },
      diferidos: [],
      slct_sitio: null,
      slct_lineaNegocio: [],
      estado: null,
      estado_diferido: -1,
      index_diferido: -1,
      listasForms: {
        sitios: [],
        linea_negocio: [],
        estados: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      /*uri_docs: "http://localhost:8000/",*/
    };
  },
  validations() {
    let descripcion = {};
    let sitio_des = {};
    let sitio_id = {};
    let diferidos = {};
    let asunto = {};
    let fecha_hora_inicial = {};
    let fecha_hora_final = {};
    let tipo_novedad = {};
    let estado = {};

    if (this.modal.accion == "Crear") {
      if (this.bitacora.estado == 1) {
        fecha_hora_final = {
          required: false,
        };
      } else {
        fecha_hora_final = {
          required,
        };
      }
      if (this.ck_sitio) {
        sitio_des = {
          required,
        };
      } else {
        sitio_id = {
          required,
        };
      }
      if (this.ck_diferido) {
        diferidos = {
          required,
        };
      } else {
        diferidos = {
          required: false,
        };
      }

      descripcion = {
        required,
      };
      asunto = {
        required,
      };
      fecha_hora_inicial = {
        required,
      };
      tipo_novedad = {
        required,
      };
      estado = {
        required,
      };
    } else if (this.modal.accion == "Editar") {
      if (this.bitacora.estado == 1) {
        descripcion = {
          required,
        };
      } else {
        descripcion = {
          required,
        };
        fecha_hora_final = {
          required,
        };
      }
      if (this.ck_diferido) {
        diferidos = {
          required,
        };
      } else {
        diferidos = {
          required: false,
        };
      }
      estado = {
        required: false,
      };
    }

    return {
      bitacora: {
        fecha_hora_final: fecha_hora_final,
        fecha_hora_inicial: fecha_hora_inicial,
        sitios: sitio_id,
        sitio_text: sitio_des,
        descripcion: descripcion,
        asunto: asunto,
        tipo_novedad: tipo_novedad,
        estado: estado,
      },
      diferidos: diferidos,
    };
  },

  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      this.filtros.sitio_id = this.slct_sitio ? this.slct_sitio.id : null;
      if (this.slct_sitio) {
        this.filtros.sitio_id = this.slct_sitio.id;
      }
      this.$refs.BitacoraNovedadesImagen == null;

      await axios
        .get("/api/admin/bitacoraNovedades?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.bitacoras = response.data;
          this.cargando = false;
          this.$refs.BitacoraNovedadesGraficas.getGrafica();
        });
    },

    async limpiarFiltros() {
      await this.getIndex();
      this.filtros = {
        id: null,
        tipo_novedad: null,
        sitio_id: null,
        linea_negocio_id: [],
        estado: null,
        fecha_inicial: null,
        fecha_final: null,
      };
      this.slct_lineaNegocio = null;
      this.slct_sitio = null;
    },

    selectLineaNegocio() {
      this.filtros.linea_negocio_id = [];
      if (this.slct_lineaNegocio) {
        this.filtros.linea_negocio_id = this.slct_lineaNegocio.map((e) => e.id);
      }
      this.getIndex();
    },

    AsuntoLong(asunto) {
      const maxLength = 30;
      return asunto.length > maxLength
        ? asunto.substring(0, maxLength).toUpperCase() + "..."
        : asunto.toUpperCase();
    },

    VistaUpdate(updated_at) {
      const date = new Date(updated_at);
      return date.toLocaleString("es-ES", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
    },

    formatearFecha(fecha) {
      const opciones = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return new Date(fecha).toLocaleDateString("es-ES", opciones);
    },

    getSitios() {
      let me = this;
      me.listasForms.sitios = [];
      var url = "api/admin/sitios/lista";
      axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    esImagen: function(nombreArchivo) {
      const extension = nombreArchivo
        .split(".")
        .pop()
        .toLowerCase();
      return ["jpg", "jpeg", "png", "gif", "bmp"].includes(extension);
    },

    esPDF: function(nombreArchivo) {
      const extension = nombreArchivo
        .split(".")
        .pop()
        .toLowerCase();
      return extension === "pdf", "PDF";
    },

    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.linea_negocio = response.data;
      });
    },

    getEstado() {
      axios.get("/api/lista/213").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTipoNovedad() {
      axios.get("/api/lista/199").then((response) => {
        this.listasForms.tipo_novedad = response.data;
      });
    },

    async show(id) {
      this.cargando = true;
      await axios
        .get("/api/admin/bitacoraNovedades/show/" + id)
        .then((response) => {
          this.bitacora = response.data;
          this.bitacora.descripcion = null;
          this.estado = this.bitacora.estado;
          this.cargando = false;
        });
    },

    async abrirModal(accion, bitacora) {
      this.modal.accion = "";
      this.modal.title = "Bitácora Novedades";
      this.limpiarForm();
      this.$refs.BitacoraNovedadesImagen.limpiarArchivo();

      if (accion == "Editar") {
        if (this.$store.getters.can("admin.bitacoraNovedades.edit")) {
          this.modal.accion = accion;
          await this.show(bitacora.id);
          if (bitacora.sitios && bitacora.sitios.length > 0) {
            this.ck_sitio = false;
          } else {
            this.ck_sitio = true;
          }
          this.bloq = true;
        } else {
          await this.$swal({
            icon: "warning",
            title: "Advertencia!!",
            text: "No tiene permisos para ejecutar está acción.",
            timer: 3000,
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
            timerProgressBar: true,
            confirmButtonText: "Aceptar",
          });
          await this.$refs.closeModal.click();
        }
      } else {
        this.modal.accion = accion;
        this.bloq = false;
      }
    },

    limpiarForm() {
      this.ck_diferido = false;
      this.ck_sitio = false;
      this.estado = null;
      this.bitacora = {
        fecha_hora_inicial: null,
        asunto: null,
        fecha_hora_final: null,
        linea_negocio: [],
        sitio: [],
        sitio_text: null,
        tipo_novedad: null,
        descripcion: null,
        estado: null,
      };
    },

    limpiarcksitio() {
      this.bitacora.sitio = null;
      this.bitacora.sitio_text = null;
    },

    limpiarDiferidoTabla() {
      this.diferidos = [];
      this.diferido = {
        campo_diferido: null,
        n_diferido: null,
      };
    },

    async save() {
      var vm = this;
      vm.cargando = true;
      var count = 0;
      let campo_diferido = null;
      let n_diferido = null;
      let formData = new FormData();
      let imagen = vm.$refs.BitacoraNovedadesImagen.files;
      let sitios = null;
      let sitio = "";
      if (vm.diferidos.length > 0) {
        let valor_campo_diferido = vm.diferidos.map(
          (diferido) => diferido.campo_diferido
        );
        let valor_ndiferido = vm.diferidos.map(
          (diferido) => diferido.n_diferido
        );

        campo_diferido = valor_campo_diferido.join(",");
        n_diferido = valor_ndiferido.join(",");
      }

      let arregloLinea = vm.bitacora.linea_negocio.map(
        (linea_negocio) => linea_negocio.id
      );

      if (vm.bitacora.sitios && vm.bitacora.sitios.length > 0) {
        sitios = vm.bitacora.sitios.map((sitio) => sitio.id);
      } else {
        sitio = vm.bitacora.sitio_text;
      }

      if (imagen) {
        for (var i = 0; i < imagen.length; i++) {
          let file = imagen[i];
          formData.append("link_imagen" + i, file);
          count = i + 1;
        }
        formData.append("sitio", sitios);
        formData.append("total", count);
        formData.append("asunto", vm.bitacora.asunto);
        formData.append("campo_diferido", campo_diferido);
        formData.append("n_diferido", n_diferido);
        formData.append("descripcion", vm.bitacora.descripcion);
        formData.append("linea_negocio", arregloLinea);
        formData.append("estado", vm.bitacora.estado);
        formData.append("tipo_novedad", vm.bitacora.tipo_novedad);
        formData.append("sitio_text", sitio);
        formData.append("fecha_hora_inicial", vm.bitacora.fecha_hora_inicial);
      } else {
        formData.append("sitio", sitios);
        formData.append("linea_negocio", arregloLinea);
        formData.append("total", count);
        formData.append("campo_diferido", campo_diferido);
        formData.append("n_diferido", n_diferido);
        formData.append("asunto", vm.bitacora.asunto);
        formData.append("descripcion", vm.bitacora.descripcion);
        formData.append("estado", vm.bitacora.estado);
        formData.append("tipo_novedad", vm.bitacora.tipo_novedad);
        formData.append("sitio_text", sitio);
        formData.append("fecha_hora_inicial", vm.bitacora.fecha_hora_inicial);
      }

      await axios
        .post("/api/admin/bitacoraNovedades", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (response) => {
          await vm.limpiarForm();
          await vm.getIndex();
          await vm.$refs.closeModal.click();
          await vm.$swal({
            icon: "success",
            title: "Se guardo exitosamente..",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          vm.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });

      vm.cargando = false;
    },

    async saveSegui() {
      var vm = this;
      vm.cargando = true;
      var count = 0;
      let campo_diferido = "";
      let n_diferido = "";
      let formData = new FormData();
      let imagen = vm.$refs.BitacoraNovedadesImagen.files;

      if (vm.diferidos.length > 0) {
        let valor_campo_diferido = vm.diferidos.map(
          (diferido) => diferido.campo_diferido
        );
        let valor_ndiferido = vm.diferidos.map(
          (diferido) => diferido.n_diferido
        );

        campo_diferido = valor_campo_diferido.join(",");
        n_diferido = valor_ndiferido.join(",");
      }

      if (imagen) {
        for (var i = 0; i < imagen.length; i++) {
          let file = imagen[i];
          formData.append("link_imagen" + i, file);
          count = i + 1;
        }
        formData.append("total", count);
        formData.append("bitacora_novedades_id", vm.bitacora.id);
        formData.append("descripcion", vm.bitacora.descripcion);
        formData.append("estado", vm.bitacora.estado);
        formData.append(
          "fecha_hora_final",
          vm.bitacora.fecha_hora_final != null
            ? vm.bitacora.fecha_hora_final
            : ""
        );
        formData.append(
          "campo_diferido",
          campo_diferido != null ? campo_diferido : ""
        );
        formData.append("n_diferido", n_diferido != null ? n_diferido : "");
      } else {
        formData.append("total", count);
        formData.append("bitacora_novedades_id", vm.bitacora.id);
        formData.append("descripcion", vm.bitacora.descripcion);
        formData.append("estado", vm.bitacora.estado);
        formData.append(
          "fecha_hora_final",
          vm.bitacora.fecha_hora_final != null
            ? vm.bitacora.fecha_hora_final
            : ""
        );
        formData.append(
          "campo_diferido",
          campo_diferido != null ? campo_diferido : ""
        );
        formData.append("n_diferido", n_diferido != null ? n_diferido : "");
      }

      await axios
        .post("/api/admin/bitacoraNovedades/seguimiento", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          vm.imagen = null;
          vm.show(vm.bitacora.id);
          vm.$refs.closeModal.click();
          vm.$swal({
            icon: "success",
            title: "El siguimiento se creo correctamente.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          vm.getIndex();
        })
        .catch(function(error) {
          vm.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });

      vm.cargando = false;
    },

    validarFecha() {
      let fecha_cierre = moment(this.bitacora.fecha_hora_final).format(
        "YYYY-MM-DD HH:mm"
      );

      let fecha_ini = moment(this.bitacora.fecha_hora_inicial).format(
        "YYYY-MM-DD HH:mm"
      );

      // Se valida que la fecha inicial de la inspección sea menor que la fecha nueva
      if (fecha_cierre < fecha_ini) {
        this.bitacora.fecha_hora_final = null;
        this.$swal({
          icon: "error",
          title: `La fecha de cierre no puede ser menor a la fecha inicial...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    async addDiferido() {
      await this.limpiarDiferido();
      this.estado_diferido = 1;
    },

    async limpiarDiferido() {
      this.diferido = {
        campo_diferido: null,
        n_diferido: null,
      };
    },

    saveDiferido() {
      this.diferidos.push(this.diferido);
      this.estado_diferido = -1;
    },

    editDiferido(index) {
      this.index_diferido = index;
      this.estado_diferido = 2;
      this.diferido = { ...this.diferidos[index] };
    },

    updateDiferido(index) {
      this.diferidos[index] = this.diferido;
      this.estado_diferido = -1;
    },

    destroyDiferido(index) {
      this.diferidos.splice(index, 1);
    },

    cancelDiferido() {
      this.estado_diferido = -1;
    },
  },

  async mounted() {
    await this.getIndex();
    await this.getEstado();
    await this.getTipoNovedad();
    await this.getSitios();
    await this.getLineasNegocio();
  },
};
</script>

<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
</style>
