<template>
  <fieldset class="well card">
    <legend class="well-legend text-bold bg-frontera text-light">
      Subir Imagen / Pdf
      <span
        type="button"
        class="bagde bg-gradient-danger btn-sm border-danger float-right m-0"
        style="cursor: pointer"
        @click="limpiarArchivo()"        
      >
        <i class="fas fa-trash-alt"></i>
      </span>
    </legend>
    <div
      class="card-body pt-0"
      :class="
        files
          ? 'alert-default-success border border-success'
          : 'alert-default-warning border border-warning'
      "
    >
      <!-- Formulario de subida -->
      <div class="row">
        <div class="col-md-12 mt-2 pt-2">
          <div class="form-group">
            <input
              type="file"
              id="link_imagen"
              ref="link"
              class="form-control-file"
              accept=".jpg, .JPG, .png, .PNG, .pdf, .PDF"
              @change="elegirDirectorio()"
              multiple
              :disabled="$parent.estado == 2"
            />
          </div>
        </div>
      </div>
      <!-- Lista de imágenes de bitacora -->
      <div class="row" v-if="id_bitacora_novedades && listaImagenes.length > 0">
        <div class="card col-sm-12">
          <div class="card-body">
            <div class="row">
              <div
                class="card col-sm-3"
                v-for="imagen in listaImagenes"
                :key="imagen.id"
              >
                <div class="card-body p-2 text-center">
                  <img
                    :src="uri_docs + imagen.link"
                    class="img-fluid mb-2"
                    style="max-height: 200px"
                    data-toggle="modal"
                    data-target="#modal-image"
                    @click="getModalData(imagen.id, imagen.link)"
                  />
                </div>
                <div
                  class="card-header"
                  v-if="$store.getters.can('admin.bitacoraNovedades.edit')"
                >
                  <div class="btn-group float-right">
                    <button
                      type="button"
                      class="btn btn-sm bg-danger"
                      @click="destroyImagen(imagen.id)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- modal -->
          <div class="modal fade" id="modal-image">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-body text-center">
                  <img :src="modal_link" class="img-fluid mb-2" />
                </div>
                <div class="modal-footer justify-content-between">
                  <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                  >
                    Cerrar
                  </button>
                  <button
                    type="button"
                    class="btn bg-danger"
                    data-dismiss="modal"
                    v-if="
                      $store.getters.can('admin.bitacoraNovedades.edit') &&
                        $parent.estado == 1
                    "
                    @click="destroyImagen(modal_id)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
              <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
          </div>
          <!-- /.modal -->
        </div>
      </div>
    </div>
  </fieldset>
</template>
<script>
import axios from "axios";

export default {
  name: "BitacoraNovedadesImagen",

  data() {
    return {
      id_bitacora_novedades: null,
      listaImagenes: [],
      link_imagen: null,
      array_Imagenes: [],
      modal_id: null,
      modal_link: null,
      files: null,
      bandera: 0,
      /*uri_docs: "http://localhost:8000/",*/
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  methods: {
    getIndex() {
      this.id_bitacora_novedades = this.$parent.id_bitacora_novedades;
      this.getImages();
    },

    getImages() {
      axios
        .get("/api/admin/bitacoraNovedades/imagen", {
          params: {
            id_bitacora_novedades: this.$parent.id_bitacora_novedades,
          },
        })
        .then((response) => {
          this.listaImagenes = response.data.data[0]
            ? response.data.data[0].imagenes
            : null;
        });
    },

    getModalData(id, link) {
      this.modal_id = id;
      this.modal_link = this.uri_docs + link;
    },

    limpiarArchivo() {
      this.files = null;
      document.getElementById("link_imagen").value = "";
    },

    elegirDirectorio() {
      this.files = this.$refs.link.files;
    },

    destroyImagen(id) {
      this.$swal({
        title: "Está seguro de eliminar esta Imagen?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/admin/bitacoraNovedades/imagen/" + id)
            .then(() => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "La imagen se eliminó correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },

  mounted() {
    this.getIndex();
  },
};
</script>
